import { useState, useEffect, useRef, useContext } from "react";
import avatar from "../../assests/svg/user.svg";
import agentAvatar from "../../assests/svg/agentAvatar.svg";
import attachement from "../../assests/svg/attachment.svg";
import smiley from "../../assests/svg/emoji.svg";
import send from "../../assests/svg/send.svg";
import Picker from "emoji-picker-react";
import { useParams } from "react-router-dom";
import { Skeleton, message } from "antd";
import { uploadFile } from "../../network/media";
import notificationAudio from "../../assests/audio/audio.mp3";

import style from "./User.module.scss";
import { getSingleConversation } from "../../network/messages";
import moment from "moment";
import { SocketContext } from "../../socket/socketContext";
import styled from "styled-components";
import { urlify } from "../../utils";

const User = () => {
  const { socket } = useContext(SocketContext);
  const { conversationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [agent, setAgent] = useState({
    firstname: "Coronation",
    lastname: "Insurance",
  });
  const [skeletonStatus, setSkeleton] = useState(true);
  const [conversationData, setConversationData] = useState(null);
  const formEL = useRef(null);
  const chatContainerRef = useRef(null);
  const audioRef = useRef(null);

  function splitify(str) {
    let arr = str.split(" ");
    arr.splice(0, 2).toString();
    arr = arr.toString().replaceAll(",", " ");
    return arr;
  }

  const scrollToBottom = () => {
    const scroll =
      chatContainerRef.current.scrollHeight -
      chatContainerRef.current.clientHeight;
    chatContainerRef.current.scrollTo(0, scroll);
  };

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  const [emoji, setEmoji] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    setSkeleton(true);

    async function fetchConversations() {
      const conversations = await getSingleConversation(conversationId);
      // const convoState = await setConversationState(conversations.conversationState);

      if (conversations) {
        const allMessages = conversations?.data?.[0]?.messages;
        const agentDetails = conversations?.data?.[0]?.agentId;

        const payload = conversations?.data?.[0];

        delete payload.messages;
        delete payload.agentId;
        delete payload.senderId;

        setConversationData(payload);

        setAgent(agentDetails);
        setMessages(allMessages);
        setSkeleton(false);
        scrollToBottom();
      }
    }
    fetchConversations();
  }, [conversationId]);

  const onEmojiClick = (event, emojiObject) => {
    inputRef.current.value = inputRef.current.value + emojiObject.emoji;
  };

  const showEmoji = () => {
    setEmoji(!emoji);
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (event.keyCode === 13) {
      const { value } = event.target;

      inputRef.current.removeEventListener("keyUp", handleSendMessage);

      if (value === "") return;

      const payload = {
        conversationId,
        messageBody: value,
        messageType: "text",
        direction: "inbound",
        createdAt: Date.now(),
        _id: Math.floor(100000 + Math.random() * 900000),
      };

      console.log(payload);
      event.target.value = "";
      chatContainerRef.current.scrollIntoView(false);

      socket.off("client:receive_my_message");
      socket.off("send_message");
      socket.emit("send_message", payload);
    }
    scrollToBottom();
  };

  function handleSubmit() {
    let value = inputRef.current.value;
    if (value === "") return;

    const payload = {
      conversationId,
      messageBody: value,
      messageType: "text",
      direction: "inbound",
    };

    // chatContainerRef.current.scrollIntoView(false);
    socket.emit("send_message", payload);

    formEL.current.reset();
    scrollToBottom();
    setEmoji(false);
  }

  useEffect(scrollToBottom, [chatContainerRef]);

  // Every of the socket Event must be in this useEffect Hook
  useEffect(() => {
    // const handleAgentSentMessage = (data) => {
    //   if (data.conversationId === conversationId) {
    //     setMessages((oldMessages) => [...oldMessages, data]);
    //     if (!document.hasFocus()) {
    //       audioRef.current?.play();
    //       document.title = "Have new messages";
    //     } else {
    //       document.title = "Coronation User Conversation";
    //     }
    //     return;
    //   }
    //   return;
    // };

    socket.on("agent:send_message", (data) => {
      if (data.conversationId === conversationId) {
        setMessages((oldMessages) => [...oldMessages, data]);
        if (!document.hasFocus()) {
          audioRef.current?.play();
          document.title = "Have new messages";
        } else {
          document.title = "Coronation User Conversation";
        }
        return;
      }
      return;
    });

    socket.on("fetch_conversations_from_client", (data) => {
      async function fetchConversations() {
        const conversations = await getSingleConversation(conversationId);

        if (conversations) {
          const allMessages = conversations?.data?.[0]?.messages;
          const agentDetails = conversations?.data?.[0]?.agentId;

          setAgent(agentDetails);
          setMessages(allMessages);
          scrollToBottom();
        }
      }
      fetchConversations();
    });

    socket.on("agent:close_conversation", (data) => {
      if (data.conversationId === conversationId) {
        setConversationData({
          ...conversationData,
          conversationState: "closed",
        });
      }
    });

    socket.on("conversation_accepted", (data) => {
      if (data.conversationId === conversationId) {
        setAgent({ firstname: data.firstname, lastname: data.lastname });
        setMessages((oldMessages) => [...oldMessages, data]);
        if (!document.hasFocus()) {
          audioRef.current?.play();
          document.title = "An agent has joined the chat";
        } else {
          document.title = "Coronation User Conversation";
        }
      }
    });

    socket.on("client:receive_my_message", (data) => {
      setMessages((oldMessages) => [...oldMessages, data]);
    });

    return () => {
      socket.off("client:receive_my_message")
      socket.off("agent:send_message");
      socket.off("fetch_conversations_from_client");
      socket.off("agent:close_conversation");
      socket.off("conversation_accepted");
    };
  }, [socket, conversationData, conversationId]);

  useEffect(() => {
    //
  }, []);

  async function handleFileUpload(event) {
    message.success(
      "File Upload started, we'll notify you once your file has been uploaded."
    );
    const { files } = event.target;

    const formData = new FormData();

    formData.append("file", files[0]);

    try {
      const { fileType, fileName, fileURL } = await uploadFile(formData);

      const payload = {
        conversationId,
        messageType: "attachment",
        messageBody: fileName,
        direction: "inbound",
        attachmentType: fileType,
        attachmentURL: fileURL,
      };

      message.success("File Upload Successful.");
      socket.emit("send_message", payload);
    } catch (error) {
      message.error(
        "An error occured while trying to upload your file. Pls try again."
      );
    }
  }
  console.log(messages);
  return (
    <div className={style.chatDetailsWrapper}>
      <audio ref={audioRef}>
        <source src={notificationAudio} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <header>
        <div>
          <img src={avatar} alt="avatar icon" />
          {agent === undefined ? (
            <p>Coronation Insurance</p>
          ) : (
            <p>{agent?.firstname + " " + agent?.lastname}</p>
          )}
        </div>
        <div></div>
      </header>
      <div className={style.main} ref={chatContainerRef}>
        <SkeletonWrapper loading={skeletonStatus} active>
          {messages?.map((e) => {
            return e.direction === "outbound" ? (
              <>
                {e.messageType === "notification" ? (
                  <p className={style.agentAccepted}>
                    <svg
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.11 7.75C2.16945 8.26695 1.38477 9.02672 0.837766 9.95012C0.290762 10.8735 0.00145428 11.9267 0 13V14H12V13C11.9985 11.9267 11.7092 10.8735 11.1622 9.95012C10.6152 9.02672 9.83055 8.26695 8.89 7.75C9.43033 7.18329 9.79244 6.47046 9.93146 5.69989C10.0705 4.92932 9.98026 4.13489 9.67202 3.4151C9.36378 2.69532 8.85106 2.08182 8.19743 1.65069C7.5438 1.21956 6.77801 0.989746 5.995 0.989746C5.21199 0.989746 4.4462 1.21956 3.79257 1.65069C3.13894 2.08182 2.62622 2.69532 2.31798 3.4151C2.00974 4.13489 1.91953 4.92932 2.05854 5.69989C2.19756 6.47046 2.55967 7.18329 3.1 7.75H3.11ZM9.87 12H2.13C2.3503 11.1396 2.8507 10.377 3.55231 9.83236C4.25392 9.28775 5.11683 8.99215 6.005 8.99215C6.89317 8.99215 7.75608 9.28775 8.45769 9.83236C9.1593 10.377 9.6597 11.1396 9.88 12H9.87ZM6 3C6.39556 3 6.78224 3.11729 7.11114 3.33706C7.44004 3.55682 7.69638 3.86918 7.84776 4.23463C7.99913 4.60008 8.03874 5.00221 7.96157 5.39018C7.8844 5.77814 7.69392 6.1345 7.41421 6.41421C7.13451 6.69391 6.77814 6.8844 6.39018 6.96157C6.00222 7.03874 5.60009 6.99913 5.23463 6.84775C4.86918 6.69638 4.55682 6.44003 4.33706 6.11114C4.1173 5.78224 4 5.39556 4 5C4 4.46956 4.21071 3.96085 4.58579 3.58578C4.96086 3.21071 5.46957 3 6 3Z"
                        fill="#95A1AA"
                      />
                    </svg>
                    <b>
                      {e.messageBody.split(" ")[0] +
                        " " +
                        e.messageBody.split(" ")[1]}{" "}
                    </b>
                    &nbsp; {splitify(e.messageBody)}
                  </p>
                ) : (
                  <div
                    style={{
                      margin: "1rem",
                      marginLeft: "3rem",
                      alignSelf: "start",
                      textAlign: "left",
                    }}
                    key={e?._id}
                  >
                    <p
                      style={{
                        wordBreak: "break-all",
                        whiteSpace: "pre-line",
                      }}
                      className={style.userMessage}
                    >
                      {e.messageType === "text" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: urlify(e.messageBody || "").replace(
                              /\*([^*]+?)\*/g,
                              "<b>$1</b>"
                            ),
                          }}
                        ></div>
                      ) : e.attachmentType?.includes("image") ? (
                        <img
                          className={style.attachement}
                          src={e.attachmentURL}
                          alt={e.messageBody}
                        />
                      ) : (
                        e.attachmentURL
                      )}
                    </p>
                    <p style={{ float: "right" }}>
                      {moment(e.createdAt).fromNow()}
                    </p>
                  </div>
                )}
              </>
            ) : (
              // <div
              //   style={{
              //     margin: "1rem",
              //     marginLeft: "3rem",
              //     alignSelf: "start",
              //     textAlign: "left",
              //   }}
              //   key={e?._id}
              // >
              //   {/* <p style={{ float: "right" }}>{item.name}</p>{" "} */}
              //   <p
              //     style={{ wordBreak: "break-all" }}
              //     className={style.userMessage}
              //   >
              //     {e.messageType === "text" ? (
              //       e.messageBody
              //     ) : e.attachmentType?.includes("image") ? (
              //       <img
              //         className={style.attachement}
              //         src={e.attachmentURL}
              //         alt={e.messageBody}
              //       />
              //     ) : (
              //       e.attachmentURL
              //     )}
              //   </p>{" "}
              //   <p style={{ float: "right" }}>
              //     {moment(e.createdAt).fromNow()}
              //   </p>
              // </div>
              // </>
              <div
                style={{
                  margin: "1rem",
                  marginRight: "3rem",
                  alignSelf: "flex-end",
                  textAlign: "left",
                }}
                key={e?._id}
              >
                <div style={{ display: "flex" }}>
                  <p
                    style={{ wordBreak: "break-all" }}
                    className={style.agentMessage}
                  >
                    {/* {e.messageBody} */}
                    {e.messageType === "text" ? (
                      e.messageBody
                    ) : e.attachmentType?.includes("image") ? (
                      <img
                        className={style.attachement}
                        src={e.attachmentURL}
                        alt={e.messageBody}
                      />
                    ) : (
                      e.attachmentURL
                    )}
                  </p>
                  <img src={agentAvatar} alt="agent avatar" />
                </div>
                <p style={{ marginTop: ".5rem" }}>
                  {moment(e.createdAt).fromNow()}
                </p>
              </div>
            );
          })}
        </SkeletonWrapper>
      </div>
      <footer>
        {conversationData?.conversationState === "closed" ? (
          <div>This conversation has been closed</div>
        ) : (
          <form ref={formEL} onSubmit={(e) => e.preventDefault()}>
            <label className={style.attachement} htmlFor="myFile">
              <input
                type="file"
                id="myFile"
                name="filename"
                onChange={handleFileUpload}
              />
              <img src={attachement} alt="attachement" />
            </label>
            <div className={style.emojiWrapper}>
              <span
                className={`${style.showEmojiBox} ${emoji ? style.open : ""}`}
              >
                {<Picker onEmojiClick={onEmojiClick} />}
              </span>
              <img onClick={showEmoji} src={smiley} alt="smiley icon" />
            </div>
            <input
              dir="ltr"
              className={style.inputMessage}
              placeholder="Type a message here..."
              type="text"
              name="message"
              id="message"
              autoComplete="off"
              onKeyUp={handleSendMessage}
              ref={inputRef}
            />
            <button onClick={handleSubmit} type="submit">
              <img src={send} alt="send icon" />
            </button>
          </form>
        )}
      </footer>
    </div>
    //    </Conversation>
  );
};

const SkeletonWrapper = styled(Skeleton)`
  width: 90vw;
  margin: 0.5rem auto;
`;

export default User;
