import instance from "./axios";

async function getUserInfo(whatsappNo) {
  const { data } = await instance.get(`/users/get/${whatsappNo}`);
  return data;
}

async function sendMessage(payload) {
  const { data } = await instance.post(
    `/conversation/offline-message`,
    payload
  );

  return data;
}

export { sendMessage, getUserInfo };
