import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import User from "./routes/User/User";
import Contact from "./routes/ContactForm";

import { SocketContextProvider } from "./socket/socketContext";

function App() {
  return (
    <Router>
      <SocketContextProvider>
        <Switch>
          <Route path="/contact" exact component={Contact} />
          {/* <Route path="/conversation/:conversationId" exact component={User} /> */}

          {/* <Route path="/user" exact component={User} /> */}

          <Route path="/:conversationId" exact component={User} />
        </Switch>
      </SocketContextProvider>
    </Router>
  );
}

export default App;
