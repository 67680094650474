import { createContext } from "react";
import io from "socket.io-client";
import { authenticateUser } from "../network/auth";
const SOCKET_SERVER = "https://wapicbot-api.herokuapp.com";
// const SOCKET_SERVER = "http://localhost:5000";

const SocketContext = createContext();

let AUTH_TOKEN = window.localStorage.getItem("JWT_TOKEN");
const conversationId = window.location.pathname.split("/")[1];

function handleError(error) {
  console.log(error);
}

if (
  AUTH_TOKEN === null &&
  conversationId !== undefined &&
  !window.location.pathname === "/contact"
) {
  authenticateUser(conversationId)
    .then((data) => {
      AUTH_TOKEN = data.jwt;
      window.localStorage.setItem("JWT_TOKEN", data.jwt);
      window.location.reload();
    })
    .catch(handleError);
}

const socket = io.connect(SOCKET_SERVER, {
  extraHeaders: { Authorization: `Bearer ${AUTH_TOKEN}` },
});

socket.on("connect", () => {
  console.log("Websocket connected successfully");
  socket
    .emit("authenticate", { token: AUTH_TOKEN })
    .on("authenticated", () => {})
    .emit("fetch_conversations", { conversationId });
});

// socket.off("client:receive_my_message");
// socket.off("send_message");
// socket.off('client:receive_my_message');

const SocketContextProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketContextProvider };
