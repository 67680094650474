import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Form, Input, Divider, Button, message } from "antd";
import styled from "styled-components";
import { getUserInfo, sendMessage } from "../network/message";

function Contact() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [initialFormData, setInitialFormData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    async function fetchUserInfo() {
      const { user } = await getUserInfo(
        location.search.replaceAll("?", "").split("=")[1]
      );
      const initialData = {
        email: user?.email,
        fullName: user?.firstname + " " + user?.lastname,
        phoneNo: user?.whatsappNo,
      };

      form.setFieldsValue(initialData);

      setInitialFormData(initialData);
    }

    fetchUserInfo();
  }, [location, form]);

  async function handleFormComplete(values) {
    setIsLoading(true);

    try {
      await sendMessage(values);
      form.resetFields();
      form.setFieldsValue({ email: "", fullName: "", phoneNo: "" });
      message.info("Message sent successfully, we'll get back to you ASAP");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error("An error occured, pls try again later");
    }
  }

  return (
    <Layout>
      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormComplete}
          requiredMark={false}
          initialValues={{ ...initialFormData }}
        >
          <h3>Fill the form below to drop a message for our live agents</h3>
          <Divider />
          <Form.Item
            label="Full Name*"
            name="fullName"
            rules={[{ required: true, message: "Full Name is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email*"
            name="email"
            rules={[
              {
                required: true,
                message: "A valid email address is required",
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone/Whatsapp no"
            name="phoneNo"
            rules={[
              {
                required: true,
                message: "Number is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Message*"
            name="message"
            rules={[{ required: true, message: "Message is required" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Divider />
          <Button htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </FormContainer>
    </Layout>
  );
}

export default Contact;

const Layout = styled.div`
  background-size: cover;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
`;

const FormContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    text-align: center;
    font-weight: bold;
  }

  form {
    background: #fff;
    width: 400px;
    padding: 1rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

    label {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
    }

    button {
      display: block;
      width: 100%;
      background-color: #48bb78 !important;
      color: #fff !important;
      border: none;
      outline: none;
      padding: 6px 10px 6px 10px;
    }
  }
`;
