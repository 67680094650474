import instance from "./axios";

const uploadFile = async (formData) => {
  const { data } = await instance.post("/media/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
};

export { uploadFile };
